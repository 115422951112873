import { useEffect, useState } from "react";
import { Batch, BatchImage } from "../types";
import {
  getMarkupBatch,
  getBatchImages,
  updateBatchImage,
  updateBatch,
  getBatchById,
} from "../../api";
import { useHistory, useLocation } from "react-router";

const useTask = (id: number) => {
  const [currentBatch, setCurrentBatch] = useState<Batch | null>(null);
  const [batchImages, setBatchImages] = useState<BatchImage[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const updateBatchId = (id: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("batch_id", id);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const preRequest = () => {
    setLoading(true);
    setError(null);
  };

  const getTaskBatchId = async () => {
    preRequest();
    try {
      const { data: batchRes } = await getMarkupBatch(id);
      const markupBatch = batchRes.data;
      return markupBatch.id.toString();
    } catch (e: any) {
      if (e.response?.data?.error_code === "batch.BatchNotFound") {
        return setTaskCompleted(true);
      }
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    }
  };

  const getBatch = async (batchId: string) => {
    if (currentBatch?.id.toString() === batchId) return;
    preRequest();
    try {
      const { data: batchRes } = await getBatchById(batchId);
      const markupBatch = batchRes.data;
      setCurrentBatch(markupBatch);
      const { data: imagesRes } = await getBatchImages(markupBatch.id);
      const batchImages = imagesRes.data;
      const images = batchImages.map((bImg) => {
        const { batch, ...img } = bImg;
        return img;
      });
      setBatchImages(images);
    } catch (e: any) {
      if (e.response?.data?.error_code === "batch.BatchNotFound") {
        return setTaskCompleted(true);
      }
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  const nextBatch = async (state: "done" | "skipped") => {
    preRequest();
    try {
      if (!currentBatch) {
        console.log(`images: ${batchImages}, batch: ${currentBatch}`);
        throw new Error("Current batch is null");
      }
      for (const img of batchImages || []) {
        if (img.markup !== null) {
          await updateBatchImage({
            id: img.id,
            markup: img.markup,
          });
        }
      }
      await updateBatch({ id: currentBatch.id, state });
      const batchId = await getTaskBatchId();
      if (batchId) {
        updateBatchId(batchId);
        return true;
      }
      return false;
    } catch (e: any) {
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  const skipBatch = () => nextBatch("skipped");
  const doneBatch = () => nextBatch("done");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const batchId = searchParams.get("batch_id");
    if (!batchId) {
      (async () => {
        const batchId = await getTaskBatchId();
        if (batchId) {
          updateBatchId(batchId);
        }
      })();
    } else {
      getBatch(batchId);
    }
  }, [location.search]);

  return {
    batchImages,
    skipBatch,
    doneBatch,
    setBatchImages,
    loading,
    error,
    taskCompleted,
    getBatch,
  };
};

export default useTask;
